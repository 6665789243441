import React, { useState, useEffect } from "react";
import "../assets/style/globalStyle.scss";
import NavBar from "../components/navBarSite";
import Footer from "../components/footer";
import BlogCard from "../components/blogCard";
import styled from "styled-components";
import { Formik } from "formik";
import { graphql } from "gatsby";

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

export default function CategoryLayout({ data }) {
  const [articles, setArticles] = useState([]);
  const isPhone = useMedia("(max-width: 767px)");
  const isLaptop = useMedia("(min-width: 1225px)");
  const [categorie, setCategorie] = useState();
  const { posts, tags } = data.categories;
  const { post, filters } = data.blog;

  useEffect(() => {
    console.log(tags[0].fieldValue);
  });

  const ButtonFilter = styled.p`
    color: black;
    font-weight: 500;
    font-size: 14px;
    background: white;
    min-width: 82px;
    margin-left: 6px;
    margin-right: 6px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px 36px 36px 36px;
    padding: 0px 15px 0px 15px;
    :hover {
      cursor: pointer;
      background: #aa6280;
      color: white;
    }
  `;

  const ButtonFilterActive = styled.p`
    color: white;
    font-weight: 500;
    font-size: 14px;
    background: white;
    min-width: 82px;
    margin-left: 6px;
    margin-right: 6px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px 36px 36px 36px;
    padding: 0px 15px 0px 15px;
    background: #aa6280;
    :hover {
      cursor: pointer;
      background: white;
      color: black;
    }
  `;

  const styles = {
    container: {
      margin: 0,
      padding: 0,
      fontFamily: "Montserrat",
    },
    background: {
      display: "flex",
      flexDirection: "column",
      backgroundSize: "cover",
      backgroundImage: `linear-gradient(180deg, rgba(216,94,75, 0.8),
      rgba(161,84,119, 0.8)),url(${"/assets/img/background.png"})`,
      height: "650px",
      marginTop: 73,
      padding: "145px 0px 0px 0px",
      alignItems: "center",
    },
    searchInput: {
      fontFamily: "Montserrat",
      width: isPhone ? "70%" : 400,
      outlineColor: "rgba(252, 206, 192, 1)",
      border: "none",
      borderRadius: "5px 0px 0px 5px",
      height: 45,
      padding: 21,
      fontSize: "16px",
      fontWeight: 400,
    },
    divider: {
      border: "1px solid #E0E0E0",
      width: !isLaptop ? "100%" : "100%",
      marginBottom: 16,
    },
    categoriesName: {
      fontSize: "16px",
      fontWeight: 400,
      color: "black",
      marginBottom: 16,
    },
    categoriesNbr: {
      fontSize: "18px",
      fontWeight: 600,
      color: "#F56D44",
      marginBottom: 16,
    },
    socialBtn: {
      fontSize: "14px",
      fontWeight: 700,
      color: "white",
      backgroundImage: `linear-gradient(90deg, #D85E4B, #A15477)`,
      borderRadius: 25,
      alignItems: "center",
      height: 45,
      width: 165,
      display: "flex",
      justifyContent: "center",
    },
  };

  return (
    <div style={styles.container}>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      ></meta>
      <NavBar></NavBar>

      {/* BACKGROUND */}
      <div style={styles.background}>
        <h1
          style={{
            fontWeight: "800",
            fontSize: "58px",
            color: "white",
            marginBottom: 15,
          }}
        >
          Notre blog
        </h1>
        <p
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "white",
            textAlign: "center",
            width: "80%",
            marginBottom: 35,
          }}
        >
          Retrouvez ici l'intégralité de nos blogs, ainsi que les blogs de nos
          partenaires réalisés pour la communautée et les fans de basketball.
        </p>
        {isPhone ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 35,
              }}
            >
              {filters.slice(0, 3).map((filters) => (
                <>
                  {tags[0].fieldValue === filters.fieldValue ? (
                    <a href={filters.fieldValue.toLowerCase()}>
                      <ButtonFilterActive>
                        {filters.fieldValue}
                      </ButtonFilterActive>
                    </a>
                  ) : (
                    <a href={filters.fieldValue.toLowerCase()}>
                      <ButtonFilter>{filters.fieldValue}</ButtonFilter>
                    </a>
                  )}
                </>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 35,
              }}
            >
              {filters.slice(filters.length - 2).map((filters) => (
                <>
                  {tags[0].fieldValue === filters.fieldValue ? (
                    <a href={filters.fieldValue.toLowerCase()}>
                      <ButtonFilterActive>
                        {filters.fieldValue}
                      </ButtonFilterActive>
                    </a>
                  ) : (
                    <a href={filters.fieldValue.toLowerCase()}>
                      <ButtonFilter>{filters.fieldValue}</ButtonFilter>
                    </a>
                  )}
                </>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 35,
            }}
          >
            {filters.map((filters) => (
              <>
                {tags[0].fieldValue === filters.fieldValue ? (
                  <a href={filters.fieldValue.toLowerCase()}>
                    <ButtonFilterActive>
                      {filters.fieldValue}
                    </ButtonFilterActive>
                  </a>
                ) : (
                  <a href={filters.fieldValue.toLowerCase()}>
                    <ButtonFilter>{filters.fieldValue}</ButtonFilter>
                  </a>
                )}
              </>
            ))}
          </div>
        )}
      </div>

      <div
        style={{
          paddingRight: isPhone ? 30 : 100,
          paddingLeft: isPhone ? 30 : 100,
          paddingBottom: 55,
          paddingTop: 64,
          display: "flex",
          flexDirection: isLaptop ? "row" : "column",
          justifyContent: "space-between",
          marginBottom: 70,
        }}
      >
        {!isLaptop && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 75,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: 800,
                  color: "black",
                  marginBottom: 45,
                }}
              >
                Catégorie
              </p>
              {filters.map((filters) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <a href={filters.fieldValue.toLowerCase()}>
                      <p style={styles.categoriesName}>{filters.fieldValue}</p>
                    </a>
                    <p style={styles.categoriesNbr}>{filters.totalCount}</p>
                  </div>
                  <div style={styles.divider}></div>
                </>
              ))}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {posts.map((post) => (
            <BlogCard
              key={post.id}
              categorie={post.frontmatter.category}
              img={post.frontmatter.image}
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              content={post.excerpt}
              name={post.frontmatter.author}
              link={post.frontmatter.slug}
            ></BlogCard>
          ))}
        </div>
        {isLaptop && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "35%",
              marginLeft: 70,
            }}
          >
            <div>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: 800,
                  color: "black",
                  marginBottom: 45,
                }}
              >
                Catégorie
              </p>
              {filters.map((filters) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <a href={filters.fieldValue.toLowerCase()}>
                      <p style={styles.categoriesName}>{filters.fieldValue}</p>
                    </a>
                    <p style={styles.categoriesNbr}>{filters.totalCount}</p>
                  </div>
                  <div style={styles.divider}></div>
                </>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* FOOTER*/}
      <Footer></Footer>
    </div>
  );
}

export const categoryQuery = graphql`
  query CategoryQuery($category: String!) {
    categories: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true, locale: "FR")
          title
          author
          category
          slug
          image
        }
        excerpt(pruneLength: 400)
        id
      }
      tags: group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      post: nodes {
        frontmatter {
          date(fromNow: true, locale: "FR")
          title
          author
          category
          slug
          image
        }
        excerpt(pruneLength: 400)
        id
      }
      filters: group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`;
